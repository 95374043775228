import React from 'react'


const  ExchangePage = () => {

    return (
        <>
            <div className="top">
                <div className="top-text"><h3>Փոխարժեք</h3></div>
                <div className="top-input">
                    <div className="appa">
                        <div className="parent-select">
                            <div>
                               
                            </div>
                            <div>
                             
                            </div>
                        </div>
                        <br />
                        <div className="parent-select">
                            <div>
                              
                            </div>
                            <div>
                               
                            </div>
                        </div>
                        <br />
                        <center><button type="button" className="btn calc-btn">Հաշվել</button></center>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="bottom-div">
                    <div className="top-text"><h3>Result</h3></div>
                    <center><div className="bottom-input">
                        <input type="text" name="" /><br />
                        <input type="text" name="" /><br />
                        <input type="text" name="" /><br />
                    </div></center>
                </div>
            </div>
        </>
    )
}




export default  ExchangePage