import React, { useState } from 'react'
import { toast } from 'react-toastify';

const ContactPage = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [textMessage, setTextMessage] = useState('')


    const handleSubmit = (e) => {
        const messageText = 'name: ' + name + '\n' + " email: " + email + '\n' + ' phone: ' + phone + '\n' + ' textMessage: ' + textMessage
        e.preventDefault()
        fetch("https://powerful-reaches-99348.herokuapp.com/mail", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // "Access-Control-Allow-Origin": "*",
                // "Access-Control-Allow-Credentials": true
            },
            body: JSON.stringify({
                messageText
            })
        }).then(res => {
            return res.json()
        }).then(res => {
            if (res.success) {
                setName('')
                setEmail('')
                setPhone('')
                setTextMessage('')
                toast.success("Նամակն ուղարկված է")
            } else if (!res.success) {
                toast.error("Խնդիր")
            }
        })
    }

    return (
        <>
            <div className="top margin-header">
                <div className="top-text"><h3 className="site-color">Կապ մեզ հետ</h3></div>
                <p className="top-p"> Լրացրեք  Ձեր տվյալները, և մենք կապ կհաստատենք ձեր հետ</p>
                <div className="top-input">
                    <div className="parent-contact flex-wrap revers-flex">
                        <div>
                            <form className="contact-form" onSubmit={(e) => handleSubmit(e)}>
                                <input type="text" value={name} placeholder="Անուն Ազգանուն" onChange={e => setName(e.target.value)} /><br /><br />
                                <input type="email" value={email} placeholder="Էլ․ հասցե" onChange={e => setEmail(e.target.value)} /><br /><br />
                                <input type="phone" value={phone} placeholder="Հեռախոսահամար" onChange={e => setPhone(e.target.value)} /><br /><br />
                                <textarea name="" value={textMessage} rows="7" cols="50" onChange={e => setTextMessage(e.target.value)} placeholder="Հաղորդագրություն">
                                </textarea><br /><br />
                                <button type="submit" className="btn calc-btn">Ուղարկել</button>
                            </form>
                        </div>
                        <div className="phone-email">
                            <div className="phone-contact"><i className="fa fa-mobile" aria-hidden="true"></i> <span> +37477139940</span></div>
                            <div><i className="fa fa-envelope" aria-hidden="true"></i> <span> info@hashvich.am</span></div>
                        </div>
                        <p className="contact">

                        </p>
                    </div>
                    <div className="social-icon">
                        <a href='#'><img src="img/facebook.png" height="40" /></a>
                        <a href="#"><img src="img/instagram.png" height="40" /></a>
                        <a href="#"><img src="img/twitter.png" height="40" /></a>
                        <a href="#"><img src="img/whatsapp.png" height="40" /></a>
                        <a href="#"><img src="img/telegram.png" height="40" /></a>
                    </div>
                    <br />
                </div>
            </div>
        </>
    )
}




export default ContactPage