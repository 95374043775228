import React, { useState } from 'react';
import { toast } from 'react-toastify';

const TeInspectionPage = () => {
    const [taretiv, setTaretiv] = useState(0)
    const [dzi, setDzi] = useState()
    const [dziIndex, setDziIndex] = useState()
    const [transport, setTransport] = useState('1')
    const [znnumFee, setZnnumFee] = useState(5500)
    const [bnapTax, setBnapTax] = useState()
    const [ready, setReady] = useState(false)
    const [periodicity, setPeriodicity] = useState()


    const todayYear = new Date().getFullYear()
    const arr1 = [
        [2.5, 2.5, 2.5, 3, 3.5, 4, 4.5, 5],
        [5, 5, 5, 6, 7, 8, 9, 10],
        [7.5, 7.5, 7.5, 9, 10.5, 12, 13.5, 15],
        [10, 10, 10, 12, 14, 16, 18, 20],
        [12.5, 12.5, 12.5, 15, 17.5, 20, 22.5, 25],
        [15, 15, 15, 18, 21, 24, 27, 30],
        [17.5, 17.5, 17.5, 21, 24.5, 28, 31.5, 35],
        [25, 25, 25, 30, 35, 40, 45, 50]
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!dzi) {
            toast.warn('Լրացրեք շարժիչի հզորությունը')
            return
        }
        if (transport == 1  || transport == 2 || transport == 3 || transport == 11) {
            setBnapTax(dzi * arr1[dziIndex][taretiv]);
        }
        setReady(true)
    }

    const handleChangeTransport = (e) => {
        const a = e.target.value
        setTransport(a)
        switch (a) {
            case '1':
                setZnnumFee(5500)
                break;
            // case '11':
            //     setZnnumFee(5500)
            //     break;
            case '2':
                setZnnumFee(7500)
                break;
            case '3':
                setZnnumFee(10500)
                break;
            case '4':
                setZnnumFee(7500)
                setBnapTax(5000)
                break;
            case '5':
                setZnnumFee(10500)
                setBnapTax(10000)
                break;
            case '6':
                setZnnumFee(10500)
                setBnapTax(15000)
                break;
            case '7':
                setZnnumFee(3500)
                setBnapTax(0)
                break;
            case '8':
                setZnnumFee(3500)
                setBnapTax(0)
                break;
        }
    }
    // const countPeriodicity= (taretiv, transport) => {
    //     if (transport == 2 || transport == 3 || transport == 11) {

    //     }
    // }

    const handleChangeDzi = (e) => {
        const a = (e.target.value)
        setDzi(a)
        switch (true) {
            case a > 0 && a <= 50:
                setDziIndex(0)
                break;
            case a >= 51 && a <= 80:
                setDziIndex(1)
                break;
            case a >= 81 && a <= 100:
                setDziIndex(2)
                break;
            case a >= 101 && a <= 150:
                setDziIndex(3)
                break;
            case a >= 151 && a <= 200:
                setDziIndex(4)
                break;
            case a >= 201 && a <= 250:
                setDziIndex(5)
                break;
            case a >= 251 && a <= 300:
                setDziIndex(6)
                break;
            case a >= 301:
                setDziIndex(7)
                break;

        }
    }

    const handleChangeTaretiv = (e) => {
        if (e.target.value >= 8) {
            setTaretiv(7)
        } else {
            setTaretiv(e.target.value)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="salary">
                <p>Թարմացված է 14 մայիսի 2022թ․</p>
                    <div className="top-text"><h3>Տեխնիկական զննություն</h3>
                    </div>
                    <hr className="sep" />
                    <div className="parent-select">
                        <div>
                            <label className="appa-label">Տրանսպորտային միջոցի տեսակ</label><br />
                            <select className="appa-select" value={transport} onChange={handleChangeTransport} >
                                <option value='1'>Թեթև մարդատար </option>
                                {/* <option value='11'>Թեթև մարդատար տաքսի </option> */}
                                <option value='2'>Ավտոբուս (մինչև 17 նստատեղով) </option>
                                <option value='3'>Ավտոբուս (17-ից ավել նստատեղով) </option>
                                <option value="4">Բեռնատար (մինչև 3․5 տոննա զանգվածով)</option>
                                <option value="5">Բեռնատար (3․5 - 12 տոննա  զանգվածով)</option>
                                <option value="6">Բեռնատար (12 տոննա զանգվածը գերազանցող)</option>
                                <option value="7">Կցորդներ կամ կիսակցորդներ</option>
                                <option value="8">Մոտոցիկլետներ կամ մոպեդներ</option>
                            </select><br />
                        </div>
                        <div>
                            <label className="salary-label">Տարեթիվ</label><br />
                            <select className="salary-select" onChange={handleChangeTaretiv} >
                                {Array.from(Array(todayYear - 1949).keys()).map(i => (
                                    <option key={i} value={i}>{todayYear - i}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    {(transport == 1 || transport == 2  || transport == 3 || transport == 11) ?
                        <div>
                            <div>
                                <label className="salary-tt"  > Ձիաուժը</label><br />
                                <input type='number' className="salary-price" onChange={handleChangeDzi} placeholder="Օրինակ ՝ 120" />
                            </div>
                        </div>
                        : <></>}
                    <button type="submit" className="btn calc-btn"  >Հաշվել</button>
                    {ready  ? taretiv < 3 ? <p><b>Առաջին տեխնիկական զննությունն իրականացվում է թողարկման 4-րդ տարում</b></p> :
                        <>
                            <div className="inspection-result-parent">
                                <h2><span className="salary-result">Տեխնիկական զննության ծառայության վճար</span>{znnumFee}</h2>
                                <span className="salary-plus"> + </span>
                                <h2><span className="salary-result">Բնապահպանության հարկ</span> {bnapTax}</h2>
                                <h2 className="salary-result-num">______________________</h2>
                                <h2 className="salary-result-num">Ընդամենը {znnumFee + bnapTax } </h2>
                            </div>
                        </>
                        : null}
                        <div>
                       
                    <br/>
                        <h4>Տեխզննում անցնելու համար անհրաժեշտ է.</h4> 
                            <ol>
                                <li>
                                <b>Վճարել մեքենայի տարեկան գույքահարկը։</b> Գույքահարկը օնլայն վճարելու համար կարող եք օգտվել <a target='_blank' href='https://pay.e-community.am/am/tax-search'>pay.e-community.am </a> 
                                 կայքից։ Անհրաժեշտ է լրացնել մեքենայի համարանիշը և վկայագրի համարը։ <b>Զրոյական տեղեկանք ներկայացնելու անհրաժեշտություն չկա։</b>
                                </li>
                                <li>
                                <b>Վճարել բնապահպանական հարկը։</b> Օնլայն վճարելու համար անցեք <a target='_blank' href='https://www.e-payments.am/hy/taxes/step3/service=4909/'>e-payments.am </a> կայքով։ 
                                Ձեր համայնքի հարկ վճարողների սպասարկման բաժնի համարը կարող եք իմանալ <a target='_blank' href='https://www.spyur.am/am/home/search/alpha/?company_name=%D5%80%D4%B1%D5%90%D4%BF+%D5%8E%D5%83%D4%B1%D5%90%D5%88%D5%82%D5%86%D4%B5%D5%90%D4%BB+%D5%8D%D5%8A%D4%B1%D5%8D%D4%B1%D5%90%D4%BF%D5%84%D4%B1%D5%86+%D5%8E%D4%B1%D5%90%D5%89%D5%88%D5%92%D4%B9%D5%85%D4%B1%D5%86+%D5%8D%D5%8A%D4%B1%D5%8D%D4%B1%D5%90%D4%BF%D5%84%D4%B1%D5%86&alpha=1'>այստեղ։ </a>
                                Օնլայն վճարելուց հետո ցանկալի է տպել անդորրագիրը և ներկայացնել տեխզննում անցնելիս։ 
                                </li>
                                <li>
                                <b>Վճարել տեխնիկական զննության ծառայության վճարը։</b> Սույն վճարը կարող եք իրականացնել տերմինալների միջոցով։ 
                                Օնլայն վճարման համար անհրաժեշտ է տեղեկանալ զննությունն իրականացնող կազմակերպության բանկային հաշվեհամարը, փոխանցել գումարը և ներկայացնել կտրոնը՝ վճարված լինելու փաստը հաստատելու համար։  
                                </li>
                                <li><b>Մոտենալ զննման կայան։</b> Զննումն անցնելուց հետո վերցնել համապատասխան փաստաթուղթը։ <a target='_blank' href='https://inspect.am/login'>Inspect.am  </a>կայքում
                                կարող եք ստուգել նաև Ձեր մեքենայի տեխզննման ժամկետները։ Անհրաժեշտ է լրացնել մեքենայի վկայագրի համարը կամ VIN համարը։ Զննման կայանների ցանկին կարող եք ծանոթանալ նույն կայքում կամ ստորև։
                                <b> Տեխզննության կտրոններ չեն տրամադրվում։</b>
                                
                                <div>
                                    <iframe src="https://inspect.am/stations" width="1000" height="700"></iframe> 
                                </div>

                                </li>
                            </ol>

                        <h4>Տեխզննում անցնելու հաճախականությունը․</h4> 
                        <ol>
                                <li>Առաջին տեխնիկական զննությունն իրականացվում է թողարկման 4-րդ տարում</li>
                                <li>Թողարկման տարեթվից հաշված` 4-ից ավելի, ներառյալ՝ 10 տարվա արտադրության թեթև մարդատար 
                                    տաքսի ավտոմոբիլներ, տրոլեյբուսներ, ավտոբուսներ, վտանգավոր բեռներ փոխադրելու համար նախատեսված 
                                    մասնագիտացված տրանսպորտային միջոցներ և դրանց կցորդներ (կիսակցորդներ)` տարին 1 անգամ, 10-ից ավելի տարվա արտադրության դեպքում` 6 ամիսը մեկ անգամ.
                                </li>
                                <li>Թողարկման տարեթվից հաշված` 4-ից ավելի, ներառյալ՝ 10 տարվա արտադրության թեթև մարդատար ավտոմոբիլներ, բեռնատար տրանսպորտային միջոցներ,
                                     ուղևորի մինչև 8 նստատեղ ունեցող բեռնաուղևորատար տրանսպորտային միջոցներ,
                                      կցորդներ (կիսակցորդներ), մոտոցիկլեր` 2 տարին մեկ անգամ.
                                </li>
                                <li>Թողարկման տարեթվից հաշված` 10-ից ավելի տարվա արտադրության թեթև մարդատար ավտոմոբիլներ, բեռնատար տրանսպորտային միջոցներ, 
                                    ուղևորի մինչև 8 նստատեղ ունեցող բեռնաուղևորատար տրանսպորտային միջոցներ, կցորդներ (կիսակցորդներ), մոտոցիկլեր` տարին 1 անգամ.
                                </li>
                        </ol>
                           


                        </div>
                </div>

            </form>
        </>
    )
}




export default TeInspectionPage