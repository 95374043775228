import React from 'react'



const AboutPage = () => {
    // const [rates, setRates] = useState([])

    // useEffect(() => {
    //     let xmlhttp = new XMLHttpRequest();
    //     xmlhttp.open('POST', "exchangerates.asmx", true)
    //     const body = '<?xml version="1.0" encoding="utf-8"?>' +
    //         '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">' +
    //         '<soap12:Body>' +
    //         '<ExchangeRatesLatest xmlns="http://www.cba.am/" />' +
    //         '</soap12:Body>' +
    //         '</soap12:Envelope>'
    //     xmlhttp.setRequestHeader('Content-Type', 'text/xml')

    //     xmlhttp.send(body)
    //     xmlhttp.onreadystatechange = () => {
    //         if (xmlhttp.readyState === 4) {
    //             parseString(xmlhttp.response, { trim: true }, function (err, result) {
    //                 setRates(result["soap:Envelope"]["soap:Body"]
    //                 [0].ExchangeRatesLatestResponse[0].ExchangeRatesLatestResult[0].Rates[0].ExchangeRate);
    //                 // console.log(JSON.stringify(result));
    //             });
    //             // console.log(JSON.parse(xmlhttp.response));
    //         }
    //     }

    // }, [])
    //0, 9, 50. 56

    // console.log(rates[0]);
    // console.log(rates[9]);
    // console.log(rates[50]);
    // console.log(rates[56]);
    return (
        <>
            <div className="top margin-header">
                <div className="top-text"><h3 className="site-color">Մեր մասին</h3></div>
                <div className="top-input">
                    <div className="parent flex-wrap">
                        <img src="img/pen.jpeg" className="site-img" alt="img" />
                        <p>Կայքը ստեղծվել է 2 ընկերների կողմից, ովքեր տարբեր հաշվարկներ կատարելու համար կորցրել են բավականին
                        մեծ ժամանակ և այդ նպատակով անհրաժեշտ են համարել խնայել մյուսների ժամանակը։
                        Կայքի հիմնական նպատակը առօրյա շատ օգտագործվող հաշվիչների ստեղծումն է մեկ հարթակում։ Կայքում տեղ գտած
                        հաշվիչները պարբերաբար կթարմացվեն օրենսդրական փոփոխություններին զուգահեռ։
                        Հաշվիչների քանակը պարբերաբար կավելանա։ Օգտատերերը կարող են գրել իրենց նախընտրած հաշվիչների մասին և
                        նրանք անպայման տեղ կգտնեն կայքում։
                            </p>
                    </div>
                    <div className="parent flex-wrap revers-flex">
                        <p className="site-p"><strong>Մեր նպատակները</strong><br />
                                <li>Առավելագույնս հարմարավետ ու հեշտ դարձնել հաշվարկների կատարումը:</li>
                                <li>Խնայել Ձեր ժամանակը:</li>
                                <li>Խթանել տեղեկատվության մատչելի հասանելիությունը:</li>
                                <li>Առավել բարդ հաշվարկներ իրականացնել առանց հավելյալ այլ գործիքների:</li>
                        </p>
                        <img src="img/calc.jpeg" alt="img" />
                    </div>
                    <div className="parent flex-wrap">
                        <img src="img/calc1.jpeg" className="site-img" alt="img" />
                        <p>  Կայքը ստեղծվել է բացառապես հայկական շուկայի համար,
                             և բոլոր հաշվարկների սկզբում ընկած են պաշտոնական ու ամենավերջին տվյալները։<br />
                             Շուտով կայքում տեղ կգտնեն վարկային, ավանդային,
                             հիփոթեքային հաշվիչներ, որոնք արդեն մշակման փուլում են։<br />
                             Մենք ունենք բավականին հաշվիչների մտքեր, որոնք կհեշտացնեն մեր կյանքը,
                             բայց մեծ սիրով սպասում ենք նաև Ձեր առաջարկներին։ <br />
                             Հուսով ենք կայքը օգնոմ է ձեզ։
                             </p>
                    </div>
                </div>
            </div>
        </>
    )
}




export default AboutPage