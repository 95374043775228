import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';


const VacationDays = () => {

    const today = new Date();
    const todayvalue = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)

    const [startDate, setStartDate] = useState(today.getFullYear() + "-01-01");
    const [endDate, setEndDate] = useState(todayvalue);
    const [schedule, setSchedule] = useState('20');
    const [ready, setReady] = useState(false);
    const [day, setDay] = useState();
    const [months, setMonths] = useState();
    const [years, setYears] = useState();
    const [vacationDays, setVacationDays] = useState();

    
    function handleSubmit(e) {
        e.preventDefault()
        
        if (startDate >= endDate) {
            toast.warn('Սխալ ամսաթվեր')
            return
        }

        setReady(true)
        let newStartDate  = new Date(startDate)
        let newEndDate  = new Date(endDate)
        let diff = newEndDate.getTime() - newStartDate.getTime();
        // console.log(diff);
        let days = diff / (1000 * 60 * 60 * 24);   
        // console.log(days);
        let years = Math.floor(days / 365)
        let months = Math.floor((days - years * 365) / 30)
        let day = days - (years * 365) - (months * 30)
        setDay(day)
        setYears(years);
        setMonths(months)

        setVacationDays(Math.round(days * schedule / 365))

    }

    return (
        <>
            <form onSubmit={handleSubmit} >
                <div className="salary">
                <p>Թարմացված է 19 սեպտեմբերի 2022թ․</p>
                    <div className="top-text"><h3>Արձակուրդային օրերի հաշվիչ</h3></div>
                    <hr className="sep" />
                    <div className="parent-select">
                        {/* <div>
                            <label className="salary-label">Աշխատավարձ</label><br />
                            <select className="salary-select" value={hashvarkType} onChange={twoCalls}>
                                <option value="0">Մաքուր</option>
                                <option value="1">Գրանցված</option>
                            </select>
                        </div> */}
                        <br />
                        <div>
                            <label className="salary-tt">Որքա՞ն ժամանակ եք աշխատում</label>
                            <br />
                            <br />
                            <div className="parent-select">
                                <div >
                                    <label>Սկիզբ</label>
                                    <input type='date' onChange={(e) => setStartDate(e.target.value)} value={startDate} className="salary-price" placeholder="Օրինակ ՝ 1 տարի" />
                                </div>
                                <div>
                                    <label>Ավարտ</label>
                                    <input type='date'  onChange={(e) => setEndDate(e.target.value)} value={endDate} className="salary-price"  placeholder="Օրինակ ՝ 1 տարի" />
                                </div>
                            </div>
                            
                            {/* <input type='number' className="salary-price" placeholder="Օրինակ ՝ 1 տարի" />
                            <input type='number' className="salary-price" placeholder="Օրինակ ՝ 2 ամիս" />
                            <input type='number' className="salary-price" placeholder="Օրինակ ՝ 3 օր" /> */}
                            {/* <input type='date' className="salary-price" placeholder="Օրինակ ՝ 100000" /> */}
                        </div>
                    </div>
                    <br />
                    <label className="salary-kut">Աշխատանքային գրաֆիկ</label><br />
                    <select className="salary-select-kut" value={schedule} onChange={(e) => setSchedule(e.target.value)}  >
                        <option value="20">Հնգօրյա</option>
                        <option value="24">Վեցօրյա</option>
                    </select>
                    <br />
                    {/* <label className="salary-tt">Արդյո՞ք աշխատում եք ՏՏ ոլորտի հարկային արտոնություններից օգտվող ընկերությունում։</label>
                    <br />
                    <select className="salary-select-tt"  >
                        <option value="21">Ոչ</option>
                        <option value="10">Այո</option>
                    </select> */}
                    <br /><br />
                    <button type="submit" className="btn calc-btn" >Հաշվել</button>
                    {ready ?
                        <> 
                         <p> Դուք աշխատել եք  {years? <><b>{years}</b> տարի, </>: null} 
                                {months? <><b>{months}</b> ամիս, </>: null} <b>{day}</b> օր  </p>

                                <h2>Արձակուրդային օրերի քանակն է {vacationDays}</h2> 
                            <div className="salary-result-parent">
                                
                            </div>
                        </>
                        : null}
                </div>
            </form>
        </>
    )
}


export default VacationDays