import React, { useEffect, useState } from 'react'
import './RealEstate.css'
import { toast } from 'react-toastify';


const RealEstatePage = () => {
    const [htmlFromCadastre, setHtmlFromCadastre] = useState({ __html: null })
    const [code, setCode] = useState()
    const [type, setType] = useState("crtnum")
    const [loader, setLoader] = useState(false)


    let data = {
        csrf: "$2y$10$0F2KixMueFYKf1KNSKNHTu0DKJfY2Di1e985cq2Rfvh2Hz71KjPGe",
        type: "crtnum",
        code: 1240587
    }

    useEffect(() => {
        fetch('https://powerful-reaches-99348.herokuapp.com/cadastre', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                csrf: "$2y$10$0F2KixMueFYKf1KNSKNHTu0DKJfY2Di1e985cq2Rfvh2Hz71KjPGe",
                type: "crtnum",
                code: 1240587
            })
        }).then((res) => {
            return res.json()
        }).then(res => {
            data.csrf = res.csrf
        })
    }, [])

    const handleSubmit = (e) => {
        // csrf = window.localStorage.getItem('csrf')
        if (e) e.preventDefault();
        funcFoo()
    }

    const funcFoo = () => {
        if (!code) {
            toast.warn('Լրացրեք կոդը')
            return
        }
        setLoader(true)
        fetch('https://powerful-reaches-99348.herokuapp.com/cadastre', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                csrf: data.csrf,
                type,
                code
            })
        }).then((res) => {
            return res.json()
        })
            .then(res => {
                setLoader(false)
                data.csrf = res.csrf
                if (!res.success && !res.message) {
                    handleSubmit()
                } else if (res.html) {
                    setHtmlFromCadastre({ __html: res.html })
                } else if (res.message) {
                    setHtmlFromCadastre({ __html: res.message })
                }
            })
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="realestate">
                    <div className="top-text"><h3>Անշարժ Գույքի Գույքահարկ</h3></div>
                    <br />
                    <div className="realestate-radio">
                        <div>
                            <div>
                                <label htmlFor="realestate-vk">Որոնել ըստ վկայականի համարի</label>
                                <input type="radio" name="re" id="realestate-vk" value={"crtnum"} onChange={e => setType(e.target.value)} checked />
                            </div>
                            <div>
                                <label htmlFor="realestate-pin">Որոնել ըստ կադաստրային ծածկագրի</label>
                                <input type="radio" name="re" id="realestate-pin" value={"cadastral_code"} onChange={e => setType(e.target.value)} />
                            </div>
                        </div>
                        <input type="text" value={code} onChange={e => setCode(e.target.value)} name="realestate-number" className="realestate-number" />
                    </div>
                    <br />
                    <br />
                    <button type="submit" className="btn calc-btn">Հաշվել</button>
                </div>
            </form>
            <div id="dataTable" dangerouslySetInnerHTML={htmlFromCadastre} />
            {loader ? <div>
                <div>
                    <p>Խնդրում ենք սպասել մոտ 18 վայրկյան։ </p>
                </div>
                <div className="lds-ellipsis"><div></div><div></div><div></div></div>
            </div>
                : <></>}


        </>
    )
}




export default RealEstatePage