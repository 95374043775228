import React, {useState} from 'react';
import { toast } from 'react-toastify';


const  VacationPage = () => {
    const [ready, setReady] = useState(false);
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-01-01"); 
    const [endDate, setEndDate] = useState(); 
    const [businessDatesCount, setBusinessDatesCount] = useState();
    const [kutakayinTokos, setKutakayinTokos] = useState('4.5')
    const notWorkingCustomDays = ['2022-01-14']
    const [hashvarkType, setHashvarkType] = useState('0')
    const [hasDifferentSalaries, setHasDifferentSalaries] = useState(false)
    const [prevYearDates, setPrevYearDates] = useState([])
    const [salary, setSalary] = useState()
    const months = ["Հունվար", "Փետրվար", "Մարտ", "Ապրիլ", "Մայիս", "Հունիս", "Հուլիս", "Օգոստոս", "Սեպտեմբեր", "Հոկտեմբեր", "Նոյեմբեր", "Դեկտեմբեր"]
    const [schedule, setSchedule] = useState("20");
    const [kutakayin, setKutakayin] = useState()
    const [droshmanish, setDroshmanish] = useState()
    const [ekamtayin, setEkamtayin] = useState()
    const [startSalary, setStartSalary] = useState()
    const [finalSalary, setFinalSalary] = useState()
    const [ekamtayinTokos, setEkamtayinTokos] = useState('21')
    
    function getBusinessDatesCount(startDate, endDate) {
        let count = 0;
        const curDate = new Date(new Date(startDate).getTime());
        while (curDate <= new Date(endDate)) {
            const dayOfWeek = curDate.getDay();
            if(dayOfWeek !== 0 && (schedule == 20 ? dayOfWeek !== 6 : true)) count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
    }


    function startDateChange(e){
        let newValue = e.target.value;
        if (newValue && endDate) {
            setBusinessDatesCount(getBusinessDatesCount(newValue, endDate));
        }
        setStartDate(e.target.value)
    }


    function endDateChange(e){
        let newValue = e.target.value;
        if (startDate && newValue) {
            setBusinessDatesCount(getBusinessDatesCount(startDate, newValue));
        }
        setEndDate(e.target.value)
    }


    function handleBusinessDatesChange(e) {
        let value = +e.target.value;
        let workingDays = 0;
        let start = new Date(startDate);

        while (workingDays !== value) {
            start.setDate(new Date(start.getDate() + 1))
            let localeDateString = start.toLocaleDateString('en-CA')
            let dayofweek = start.getDay()
            if(dayofweek === 0 || dayofweek === 6 || notWorkingCustomDays.includes(localeDateString)){
                continue
            } else {
                workingDays++;
            }
        }
    
        setBusinessDatesCount(value)
        setEndDate(start.toLocaleDateString('en-CA'))
    }

    function handleHasDifferentSalariesChange(e){
        console.log("ssdds");
        if (e.target.checked) {
            let arr= []
            for(let i = 1; i<=12; i++){
                let start = new Date(startDate)
                start.setMonth(start.getMonth()-i)
                arr.push({
                    month: start.getMonth(),
                    year: start.toLocaleDateString('en-us', {year: 'numeric'}),
                    salary: salary,
                    additionalIncomes: null
                })
            }
            setHasDifferentSalaries(true)
            setPrevYearDates(arr)
        } else {
            setHasDifferentSalaries(false)
            setPrevYearDates([])
        }
        
    }

    function handleChangeAdditionalIncomes(e, index) {
        prevYearDates[index].additionalIncomes = +e.target.value
        setPrevYearDates([...prevYearDates])
    } 

    function handleChangeSalary(e, index) {
        prevYearDates[index].salary = +e.target.value
        setPrevYearDates([...prevYearDates])
    } 

    const handleSubmit = (e) => {
        if (e) e.preventDefault()
        if (!salary) {
            toast.warn('Լրացրեք աշխատավարձի չափը')
            return
        }

        // const hashvarkType = e.target.elements[0].value;

        let sal = parseInt(salary)

        if (hasDifferentSalaries) {
            sal = (prevYearDates.reduce((a, b) => a.salary + a.additionalIncomes + b.salary + b.additionalIncomes, 0) / 12)
        }

        let ekamt
        let kutak
        let droshm
        let x
        if (hashvarkType == 0) {
            
            if (kutakayinTokos == 0) {
                kutak = 0
                switch (true) {
                    case (sal <= 76500):
                        droshm = 1500
                        break;
                    case (sal > 76500 && sal <= 153000):
                        droshm = 3000
                        break;
                    case (sal > 153000 && sal <= 384500):
                        droshm = 5500
                        break;
                    case (sal > 384500 && sal <= 771500):
                        droshm = 8500
                        break;
                    case (sal > 771500):
                        droshm = 15000
                }
                x = Math.round((sal + droshm) * 100 / (100 - ekamtayinTokos))
                setKutakayin(0)
                setDroshmanish(droshm)
                setEkamtayin(Math.round(x * ekamtayinTokos / 100))
                setFinalSalary(Math.round(x))
            } else if (kutakayinTokos == 4.5) {
                switch (true) {
                    case (sal <= 73000):
                        droshm = 1500
                        x = Math.round((sal + droshm) * 100 / (100 - ekamtayinTokos - kutakayinTokos))
                        setEkamtayin(Math.round(x * ekamtayinTokos / 100))
                        setKutakayin(Math.round(x * 4.5 / 100))
                        setFinalSalary(Math.round(x))
                        setDroshmanish(droshm)
                        break;
                    case (sal > 73000 && sal <= 146000):
                        droshm = 3000
                        x = Math.round((sal + droshm) * 100 / (100 - ekamtayinTokos - kutakayinTokos))
                        setEkamtayin(Math.round(x * ekamtayinTokos / 100))
                        setKutakayin(Math.round(x * kutakayinTokos / 100))
                        setFinalSalary(Math.round(x))
                        setDroshmanish(droshm)
                        break;
                    case (sal > 146000 && sal <= 367000):
                        droshm = 5500
                        x = Math.round((sal + droshm) * 100 / (100 - ekamtayinTokos - kutakayinTokos))
                        setEkamtayin(Math.round(x * ekamtayinTokos / 100))
                        setKutakayin(Math.round(x * kutakayinTokos / 100))
                        setFinalSalary(Math.round(x))
                        setDroshmanish(droshm)
                        break;
                    case (sal > 367000 && sal <= 704000):
                        droshm = 8500
                        x = Math.round((sal + droshm - 32500) * 100 / (100 - ekamtayinTokos - 10))
                        setEkamtayin(Math.round(x * ekamtayinTokos / 100))
                        setKutakayin(Math.round((x * 10 / 100) - 32500))
                        setFinalSalary(Math.round(x))
                        setDroshmanish(droshm);
                        break;
                    case (sal > 704000 && sal <= 711100):
                        droshm = 15000
                        x = Math.round((sal + droshm - 32500) * 100 / (100 - ekamtayinTokos - 10))
                        setEkamtayin(Math.round(x * ekamtayinTokos / 100))
                        setKutakayin(Math.round((x * 10 / 100) - 32500))
                        setFinalSalary(Math.round(x))
                        setDroshmanish(droshm)
                        break;
                    case (sal > 711100):
                        droshm = 15000
                        setKutakayin(69500)
                        x = Math.round((sal + droshm + 69500) * 100 / (100 - ekamtayinTokos))
                        setEkamtayin(Math.round(x * ekamtayinTokos / 100))
                        setFinalSalary(Math.round(x))
                        setDroshmanish(droshm)
                }

            }
        } else if (hashvarkType == 1) {
            kutak = 0
            switch (true) {
                case (sal <= 100000):
                    droshm = 1500
                    if (kutakayinTokos) {
                        kutak = sal * kutakayinTokos / 100
                    }
                    break;
                case (sal > 100000 && sal <= 200000):
                    droshm = 3000
                    if (kutakayinTokos) {
                        kutak = sal * kutakayinTokos / 100
                    }
                    break;
                case (sal > 200000 && sal <= 500000):
                    droshm = 5500
                    if (kutakayinTokos) {
                        kutak = sal * kutakayinTokos / 100
                    }
                    break;
                case (sal > 500000 && sal <= 1000000):
                    droshm = 8500
                    if (kutakayinTokos) {
                        kutak = (sal * kutakayinTokos / 100) - 32500
                    }
                    break;
                case (sal > 1000000 && sal <= 1020000):
                    droshm = 15000
                    if (kutakayinTokos) {
                        kutak = (sal / kutakayinTokos) - 32500
                    }
                    break;
                case (sal > 1020000):
                    droshm = 15000
                    if (kutakayinTokos) {
                        kutak = 69500
                    }
            }
            setDroshmanish(droshm)
            setKutakayin(kutak)
            ekamt = sal * ekamtayinTokos / 100
            setEkamtayin(ekamt)
            setFinalSalary(Math.round(sal - ekamt - kutak - droshm))
        }
        setStartSalary(sal)
        setReady(true)
    }

console.log(prevYearDates);
    return (
        <>
            <form onSubmit={handleSubmit} >
                <div className="salary">
                <p>Թարմացված է 19 սեպտեմբերի 2022թ․</p>
                    <div className="top-text"><h3>Արձակուրդային օրերի հաշվիչ</h3></div>
                    <hr className="sep" />
                    <div>
                        
                        <br />
                        <div>
                            <div className='parent-select'>
                                <div >
                                    <label>Սկիզբ</label>
                                    <input type='date' min={startDate} max={new Date().getFullYear() + 1 + "-12-31"}  
                                            onChange={(e) => startDateChange(e)} value={startDate} className="salary-price" />
                                </div>
                                <div>
                                    <label>Ավարտ</label>
                                    <input type='date'  min={new Date(startDate).getFullYear() + "-01-01"} max={new Date().getFullYear() + "-12-31"}
                                            onChange={(e) => endDateChange(e)} value={endDate} className="salary-price"  />
                                </div>
                            </div>
                            <br />
                            <div>
                                <div>
                                    <label>Արձակուրդային օրեր</label>
                                    <input type='number' value={businessDatesCount} onChange={handleBusinessDatesChange}></input>
                                </div>
                                <div>
                                    <label className="salary-kut">Աշխատանքային գրաֆիկ</label><br />
                                    <select className="salary-select-kut" value={schedule} onChange={(e) => setSchedule(e.target.value)}  >
                                        <option value="20">Հնգօրյա</option>
                                        <option value="24">Վեցօրյա</option>
                                    </select>
                                </div>
                            </div>
                            <br />
                            <br />

                            <div className="realestate-radio">
                                <label htmlFor="state-checkbox" id="state-label">Ձեր աշխատավարձը և այլ հավելավճարները <br/> տարբերվել են վերջին 12 ամիսներին </label>
                                 <input id="state-checkbox" className="fas" type="checkbox" value={hasDifferentSalaries} onChange={(e)=> handleHasDifferentSalariesChange(e)} />
                            </div>
                            { hasDifferentSalaries ?
                                <div>
                                    <table  className="vacation-input">
                                        <thead>
                                            <tr>
                                                <th>Ամիս</th>
                                                <th>Տարի</th>
                                                <th>Աշխատավարձ</th>
                                                <th>Հավելավճար + պարգևավաճար</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prevYearDates.map((el, index) => (
                                            <tr key={index}>
                                                <td>{months[el.month]}</td>
                                                <td>{el.year}</td>
                                                <td> <input type="number" value={el.salary} onChange={(e)=>handleChangeSalary(e, index) } /></td>
                                                <td> <input type="number" value={el.additionalIncomes} onChange={(e)=>handleChangeAdditionalIncomes(e, index)} /></td>
                                            </tr>
                                            ))}
                                            
                                        </tbody>
                                    </table>
                                </div>
                                : null
                            }
                            <br />
                            <div className='parent-select'>
                                <div>
                                    <label className="salary-label">Նշեք աշխատավարձի տեսակը, որը գրելու եք</label><br />
                                    <select className="salary-select" value={hashvarkType} onChange={(e) => setHashvarkType(e.target.value)}>
                                        <option value="0">Մաքուր</option>
                                        <option value="1">Գրանցված</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Աշխատավարձ</label>
                                    <input type='number' value={salary} onChange={(e)=>setSalary(e.target.value)}></input>
                                </div>
                            </div>
                            <br />
                            <div style={{"display": "flex"}}>
                                
                                
                            </div>
                            <br />
                            <div>
                                    <label className="salary-kut">Մասնակցում եք արդյո՞ք պարտադիր կուտակային կենսաթոշակային համակարգին: Եթե այո, ապա ի՞նչ հիմունքներով</label><br />
                                    <select className="salary-select-kut" onChange={(e) => setKutakayinTokos(e.target.value)}  >
                                        <option value="4.5">Այո, կամավոր</option>
                                        <option value="4.5">Այո, պարտադիր կամ մինչ 07/2018 միացած կամավոր</option>
                                        <option value="0">Չեմ Մասնակցում</option>
                                    </select>
                                </div>

                            </div>
                            
                            
                            {/* <input type='number' className="salary-price" placeholder="Օրինակ ՝ 1 տարի" />
                            <input type='number' className="salary-price" placeholder="Օրինակ ՝ 2 ամիս" />
                            <input type='number' className="salary-price" placeholder="Օրինակ ՝ 3 օր" /> */}
                            {/* <input type='date' className="salary-price" placeholder="Օրինակ ՝ 100000" /> */}
                    </div>
                    
                    <br />
                    {/* <label className="salary-tt">Արդյո՞ք աշխատում եք ՏՏ ոլորտի հարկային արտոնություններից օգտվող ընկերությունում։</label>
                    <br />
                    <select className="salary-select-tt"  >
                        <option value="21">Ոչ</option>
                        <option value="10">Այո</option>
                    </select> */}
                    <br /><br />
                    <button type="submit" className="btn calc-btn" >Հաշվել</button>
                    {ready ?
                        <>
                            <div className="salary-result-parent">
                                <h2> <span className="salary-result">Աշխատավարձ</span> {startSalary}</h2>
                                <span className="salary-plus">-</span>
                                <h2> <span className="salary-result">Եկամտային հարկ</span> {ekamtayin}</h2>
                                <span className="salary-plus">-</span>
                                <h2> <span className="salary-result">Կուտակային կենս․</span> {kutakayin}</h2>
                                <span className="salary-plus">-</span>
                                <h2> <span className="salary-result">Դրոշմանշային վճար</span> {droshmanish}</h2>
                                <h2 className="salary-result-num">______________________</h2>
                                <h2 className="salary-result-num"> <span className="salary-result">Արդյունք</span> {finalSalary}</h2>
                            </div>
                        </>
                        : null}
                </div>
            </form>
        </>
    )
}




export default  VacationPage