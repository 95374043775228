import React from 'react'


const  AllPage = () => {

    return (
        <>
            <div className="top">
                <div className="top-input">
                    <div className="appa">
                        <h3>Ընտրեք հաշվիչ</h3>
                    </div>
                </div>
            </div>
        </>
    )
}




export default  AllPage