import AppaPage from '../CalcPage/AppaPage'
import CarsPage from '../CalcPage/CarsPage'
import RealEstatePage from '../CalcPage/RealEstatePage'
import SalaryPage from '../CalcPage/SalaryPage'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

const HomePage = () => {

    const [rates, setRates] = useState()

    const sliceNum = (num) => {
        return Number(num.toString().slice(0, -2));
    }

    useEffect(() => {
        fetch('https://powerful-reaches-99348.herokuapp.com/rates').then((res) => {
            return res.json()
        }).then((data) => {
            if (data.success) {
                // setRates(data.data)
                setRates(data.data.reduce((prev, cur) => {
                   return  prev + "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0" + cur["ISO"] + ' : '+ sliceNum(cur['Rate']) +' ('+ sliceNum(cur['Difference']) +')'
                }, ''))
            }
        })
    }, [])
    return (
        <>
            <div className="container-fluid color-black">
                <marquee direction="right">
              Կենտրոնական բանկ : {rates}
            </marquee>
            </div>
            <div className="container-fluid">
                <h3 className="center-teg">Մեր հաշվիչները</h3>
            </div>
            <div className="icon-part">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col1">
                            <div className="icon-part-parent">
                                <Link to='/salary'><div className="calc-icon"><i className="fa fa-suitcase" aria-hidden="true"></i></div>
                                    <p className="calc-icon-text">Աշխատավարձի հաշվիչ</p></Link>
                            </div>
                        </div>
                        <div className="col col2">
                            <div className="icon-part-parent">
                                <Link to='realEstate'><div className="calc-icon"><i className="fa fa-home" aria-hidden="true"></i></div>
                                    <p className="calc-icon-text">Գույքահարկի հաշվիչ</p></Link>
                            </div>
                        </div>
                        <div className="col col3">
                            <div className="icon-part-parent">
                                <Link to='appa'><div className="calc-icon"><i className="fa fa-cab" aria-hidden="true"></i></div>
                                    <p className="calc-icon-text">ԱՊՊԱ</p></Link>
                            </div>
                        </div>
                        <div className="col col4">
                            <div className="icon-part-parent">
                                <Link to='inspection'><div className="calc-icon"><i className="fa fa-bus" aria-hidden="true"></i></div>
                                    <p className="calc-icon-text">Տեխզննում</p></Link>
                            </div>
                        </div>
                        {/*  <div className="col col5">
                            <div className="icon-part-parent">
                                <div className="calc-icon"><i className="fa fa-tree" aria-hidden="true"></i></div>
                                <p className="calc-icon-text">Վարկային</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <h3 className="center-text">Վերջին թարմացումներ</h3>
            </div>
            <div className="container-fluid flex-calc">
                <p className="container-fluid home-page-text">
                    <strong>Աշխատավարձ ՝</strong><br />
2021թ. հունվարի 1-ից Զինծառայողների ապահովագրության հիմնադրամի դրոշմանիշային վճարները կատարվում են հետևյալ դրույքաչափերով.
<br />
- Մինչև 100 000 դրամ հաշվարկման բազայի դեպքում՝ 1500 դրամ
<br />
- 100 001-ից մինչև 200 000 դրամ հաշվարկման բազայի դեպքում՝ 3000 դրամ
<br />
- 200 001-ից մինչև 500 000 դրամ հաշվարկման բազայի դեպքում՝ 5500 դրամ
<br />
- 500 001-ից մինչև 1 000 000 դրամ հաշվարկման բազայի դեպքում՝ 8500 դրամ
<br />
- 1 000 001 դրամ և ավելի հաշվարկման բազայի դեպքում՝ 15 000 դրամ
<br /><br />
                    <strong>ԱՊՊԱ ՝</strong><br />
ԱՊՊԱ պայմանագիրը մեկ ավտոտրանսպորտային միջոցի հետ կապված մեկ ապահովագրական պատահարի համար ապահովում է հետևյալ ծածկույթը.
<br />
Անձնական վնասների դեպքում՝
<br />
- 3.300.000 ՀՀ դրամ՝ յուրաքանչյուր տուժողի համար,
<br />
- 33.000.000 ՀՀ դրամ՝ յուրաքանչյուր ապահովագրական պատահարի համար:
<br />
Գույքին պատճառված վնասների դեպքում՝
<br />
- 1.800.000 ՀՀ դրամ՝ յուրաքանչյուր տուժողի համար,
<br />
- 18.000.000 ՀՀ դրամ՝ յուրաքանչյուր ապահովագրական պատահարի համար:
<br /><br />
                    <strong>Անշարժ Գույքի Գույքահարկ ՝</strong><br />
Հայաստանում ներդրվում է անշարժ գույքի գնահատման և հարկման նոր համակարգ, որով գույքահարկը հաշվարկվելու է անշարժ գույքի ոչ թե կադաստրային, այլ շուկայական մոտարկված արժեքի հիման վրա։<br />
2021թ․ հունվարի 1-ից կիրառության մեջ մտնող նոր համակարգի արդյունքում գույքահարկի վճարները կավելանան։ Սակայն հարկային բեռի կտրուկ աճը կանխելու համար ՀՀ կառավարությունն առաջարկում է նոր համակարգին անցում
 կատարել աստիճանաբար։ Համաձայն նախագծի, որը հավանաբար առաջիկայում կընդունվի, 2021թ․-ին սեփականատերը կվճարի հաշվարկված գույքահարկի 25%-ը, 2022թ-ին` 30%-ը,
2023թ-ին՝ 35%-ը, 2024թ․-ին՝ 50%-ը, 2025թ․-ին՝ 75%-ը, իսկ 2026թ․-ից՝ արդեն ամբողջ հարկը։
</p>
            </div>

            <div className="container-fluid flex-calc">
                <Link className="btn calc-btn all-calc" to='/calcpage'>Տեսնել բոլոր հաշվիչները</Link>
            </div>

            {/* <SalaryPage />
            <hr />
            <CarsPage />
            <hr />
            <AppaPage />
            <hr />
            <RealEstatePage /> */}

        </>
    )
}




export default HomePage