import React, { useState } from 'react'
import { toast } from 'react-toastify';
import './pension.css'


const PensionPage = () => {
    const [workYears, setWorkYears] = useState()
    const [disability, setDisability] = useState(0)
    const [pension, setPension] = useState(0)
    const himnakan = 18000;
    const minimum = 28600;
    let coefficient  = 0.1
    
    const handleSubmit = (e) => {
        e.preventDefault()
        let pension1 = 0

        if (workYears <= 10) {
            coefficient  = 0.1 * workYears
            pension1 = himnakan + workYears * 950 * coefficient
        } else if (workYears >10 && workYears <= 40) {
            coefficient = 1 + 0.01 * (workYears - 10)
            pension1 = himnakan + (10 * 950  + (workYears - 10) * 500) * coefficient
        } else if (workYears > 40) {
               
            coefficient = 1.3 + 0.02 * (workYears - 40)

            if (coefficient > 2) {
                coefficient = 2
            }
            pension1 = himnakan + (10 * 950  + (workYears - 10) * 500) * coefficient
        }

        if (disability == 1) {
            pension1 += himnakan * 40 / 100 
        } else if (disability == 2) {
            pension1 += himnakan * 20 / 100 
        }
        if (pension1 < minimum){
            setPension(minimum)
            return
        }
        setPension(pension1)
     



    }

  
    return (
        <div className="top salary">
            <p>Թարմացված է 15 հունիսի 2022թ․</p>
                <div className="top-text"><h3>Տարիքային կենսաթոշակի հաշվիչ</h3></div>
                <div className="top-input">
                <hr className="sep" />
                    <form onSubmit={handleSubmit}>
                        
                        <div className="parent-select">
                                <div>
                                    <div>
                                        <label className="salary-tt"  > Աշխատանքային ստաժ</label><br />
                                        <input type='number' className="salary-price" onChange={(e) => setWorkYears(e.target.value)} placeholder="Օրինակ՝ 12" />
                                    </div>
                                </div>
                                <div>
                                    <label className="appa-label">Ունե՞ք հաշմանդամություն</label><br />
                                    <select className="appa-select" value={disability} onChange={(e) => setDisability(e.target.value)} >
                                        <option value='0'>Չունեմ</option>
                                        <option value="1">Առաջին կարգ</option>
                                        <option value="2">Երկրորդ կարգ</option>
                                        <option value="3">Երրորդ կարգ</option>
                                    </select><br />
                                </div>
                        </div> 
                            <button type="submit"  className="btn calc-btn">Հաշվել</button>
                    </form>
                </div>

                
                <h3> {pension ? "Արդյունք - " + pension + " դրամ": ""}</h3>
        </div>

    )
}




export default React.memo(PensionPage)