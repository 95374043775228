import React, { useState } from 'react'



const AppaPage = () => {

    const [ZHG, setZHG] = useState('1')
    const [BM, setBM] = useState('1')
    const [transport, setTransport] = useState('1')
    const [busType, setBusType] = useState(false)
    const [goal, setGoal] = useState('1')
    const [engine, setEngine] = useState('0.8')
    const [price, setPrice] = useState()
    const HA = 33122
    const [prevContract, setPrevContract] = useState('0')
    const periodCoefficients = [0.5, 0.65, 0.75, 0.82, 0.85, 0.88, 0.91, 0.94, 0.97, 1, 1.1, 1.15, 1.25, 1.3, 1.4, 1.5, 1.6, 2, 2.3, 2.5, 2.5, 2.7, 2.9, 3.0, 3.0]

    const handleTransportChange = (e) => {

        const vl = e.target.value


        if (vl == -1) {
            setBusType(true)
            setTransport("1.44")
            return
        } else {
            setBusType(false)
            setTransport(vl)
        }


    }

    const handleSubmit = () => {
        
        if (prevContract == '1')
        {

        switch (transport) {

            case "1":
                setPrice(Math.round((HA * transport * goal * engine  + 3000) * BM * ZHG / 1000) * 1000 ) 
                break
            case "1.185":
                setPrice(Math.round((HA * transport * engine + 3000) * BM * ZHG / 1000) * 1000 )
                break
            case "1.44":
                setPrice(Math.round((HA * transport + 3000) * BM * ZHG / 1000) * 1000 )
                break
            case "1.133":
                setPrice(Math.round((HA * transport  + 3000) * BM * ZHG / 1000) * 1000 )
                break
            case "1.33":
                setPrice(Math.round((HA * transport + 3000) * BM * ZHG / 1000) * 1000 )
                break
            case "0.59":
                setPrice(Math.round((HA * transport + 3000) * BM * ZHG / 1000) * 1000 )
                break
        }
    }
    else if (prevContract == '0') {
        console.log(BM);
        console.log(ZHG);
        setPrice(Math.round(41000 *  BM * ZHG / 1000) * 1000)
    }


    }

    return (
        <>
            <div className="top">
            <p>Թարմացված է 15 մայիսի 2022թ․</p>
                <div className="top-text"><h3>ԱՊՊԱ Հաշվիչ</h3></div>
                <div className="top-input">
                <hr className="sep" />
                    <div className="appa">

                        <div>
                            <label className="appa-label">2017թ․ նոյեմբերի 1ից 2021թ դեկտեմբերի 31ի ընթացքում  ունեցե՞լ եք պայմանագիր տվյալ մեքենայի համար</label><br />
                            <select className="appa-select" value={prevContract} onChange={(e) => setPrevContract(e.target.value)}>
                                <option value='1'>Այո</option>
                                <option value="0">Ոչ</option>

                            </select><br />
                        </div>
                    {prevContract == '1' ? 
                    <>
                        <div>
                            <label className="appa-label">Տրանսպորտային միջոցի տեսակ</label><br />
                            <select className="appa-select" onChange={handleTransportChange}>
                                <option value='1'>Թեթև մարդատար </option>
                                <option value="1.185">Բեռնատար (ներառյալ ուղևորաբեռնատար)</option>
                                <option value="-1">Ավտոբուս (ներառյալ միկրոավտոբուս), տրոյլեբուս</option>
                                <option value="0.59">Մոտոտրանսպորտ (ներառյալ մոտոցիկլ, տրիցիկլ, քվադրիցիկլ)</option>
                                <option value="0.59">Այլ</option>
                            </select><br />
                        </div>
                        <div className="parent-select">
                            {busType ?
                                <div>
                                    <label className="appa-label-count">Նստատեղերի քանակ</label><br />
                                    <select className="appa-select-count" onChange={e => setTransport(e.target.value)}>
                                        <option value="1.44" >Մինչև 17 (առանց վարորդի)</option>
                                        <option value="1.133">18 և ավել (առանց վարորդի)</option>
                                    </select><br />
                                </div>
                                : <></>}
                            {transport == 1 ?
                                <div>
                                    <label className="appa-label-type">Օգտագործման նպատակ</label><br />
                                    <select className="appa-select-type" onChange={e => setGoal(e.target.value)}>
                                        <option value="1" >Անձնական</option>
                                        <option value="1">Հանրային տրանսպորտ</option>
                                        <option value="1.8">Տաքսի/վարձակալություն</option>
                                        <option value="1.03">Ծառայողական/առևտրային</option>
                                    </select><br />
                                </div>
                                : <></>}
                            {transport == 1 || transport == 1.185 ?

                                <div>
                                    <label className="appa-label-power">ՏՄ շարժիչի հզորությունը</label><br />
                                    <div>
                                        <select className="appa-select-power" onChange={e => setEngine(e.target.value)}>
                                            <option value="0.8" >Մինչև 80 ձիաուժ</option>
                                            <option value="1">81-ից 140 ձիաուժ</option>
                                            <option value="1.38">141-ից 230 ձիաուժ</option>
                                            <option value="1.64">231 և ավելի ձիաուժ</option>
                                        </select><br />
                                    </div>

                                </div>

                                : <></>}
                                
                        </div> 
                    </>
: null}
                         <div className="parent-select">
                            <div>
                                <label className="appa-label-date">Ժամկետ</label><br />
                                <select className="appa-select-date" onChange={e => setZHG(e.target.value)}>
                                    <option value="1">1 տարի</option>
                                    <option value="0.95">11 ամիս</option>
                                    <option value="0.85">10 ամիս</option>
                                    <option value="0.77">9 ամիս</option>
                                    <option value="0.7">8 ամիս</option>
                                    <option value="0.65">7 ամիս</option>
                                    <option value="0.6">6 ամիս</option>
                                    <option value="0.5">5 ամիս</option>
                                    <option value="0.4">4 ամիս</option>
                                    <option value="0.33">3 ամիս</option>
                                    <option value="0.25">2 ամիս*</option>
                                    <option value="0.2">16 օրից մինչև 1 ամիս ներառյալ</option>
                                    <option value="0.15">11 օրից մինչև 15 օր ներառյալ</option>
                                    <option value="0.1">10 օր</option>
                                </select><br />
                            </div>
                            <div>
                                <label className="appa-label-date">Բոնուս մալուսի դաս</label><br />
                                <select className="appa-select-date" value={BM} onChange={e => setBM(e.target.value)}>
                                    {periodCoefficients.map((i, index) => {
                                        return (
                                        <option value={i}>Դաս {index + 1}</option>)
                                    }) }
                                    
                                    {/* <option value="1">Դաս 2</option>
                                    <option value="2">Դաս 3</option>
                                    <option value="0.82">Դաս 4</option>
                                    <option value="0.85">Դաս 5</option>
                                    <option value="0.88">Դաս 6</option>
                                    <option value="0.91">Դաս 7</option>
                                    <option value="0.94">Դաս 8</option>
                                    <option value="0.97">Դաս 9</option>
                                    <option value="1" >Դաս 10</option>
                                    <option value="1.1">Դաս 11</option>
                                    <option value="1.15">Դաս 12</option>
                                    <option value="1.25">Դաս 13</option>
                                    <option value="1.3">Դաս 14</option>
                                    <option value="1.4">Դաս 15</option>
                                    <option value="1.5">Դաս 16</option>
                                    <option value="1.6">Դաս 17</option>
                                    <option value="2">Դաս 18</option>
                                    <option value="2.3">Դաս 19</option>
                                    <option value="2.5">Դաս 20</option>
                                    <option value="2.5">Դաս 21</option>
                                    <option value="2.7">Դաս 22</option>
                                    <option value="2.9">Դաս 23</option>
                                    <option value="3.0">Դաս 24</option>
                                    <option value="3.0">Դաս 25</option> */}
                                </select><br />
                            </div>
                        </div>
                        <br />
                        <br />
                        <button type="button" onClick={handleSubmit} className="btn calc-btn">Հաշվել</button>
                    </div>
                </div>
                <h3> {price ? "Արդյունք - " + price + " դրամ": ""}</h3>
            </div>
        </>
    )
}




export default React.memo(AppaPage)